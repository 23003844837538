.button-filled{
	background: #8ac2fd;
	width: 100%;
	min-height: 52px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	cursor: pointer;
}

.button-filled:not(.button-filled_disabled):hover{
	cursor: pointer;
}

.button-filled_disabled{
	background: #7d8387;
	cursor: default;
}

.button-filled__text{
	font-family: var(--firstFont);
	font-weight: 600;
	font-size: var(--fontSizeBigText);
	color: #fff;
	line-height: 1;
}
