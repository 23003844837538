.container {
    padding: 20px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

@media (max-width: 450px) {
    .container {
        padding: 20px 10px;
    }
}
