.main {
    max-width: 828px;
    min-width: 500px;
    min-height: 517px;
    background: #fff;
    padding: 20px 25px;

    &__form {
        margin-top: 4px;

        &-two-inputs-block {
            margin-top: 19px;
            display: flex;
        }
        &-one-inputs-block{
            margin-top: 19px;
            display: flex;
        }
        &-input{
            width: 100%;

            &:nth-child(1){
                margin-right: 21px;
            }

            &-alone{
                width: 100%;
                margin-right: 0;
            }
        }
        &__buttons{
            margin-top: 30px;
            margin-bottom: 6px;
            display: flex;

            &-save{
                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.4px;
                color: #FFFFFF;

                padding: 10px 22px;

                background: #7367F0;
                border-radius: 5px;

                &:hover{
                    transform: scale(0.95);
                }
                cursor: pointer;
            }

            &-discard{
                margin-left: 16px;

                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.4px;
                color: #82868B;

                padding: 10px 23px;

                background: #ffffff;
                border: 1px solid #82868B;
                border-radius: 5px;

                &:hover{
                    transform: scale(0.95);
                }
                cursor: pointer;
            }
        }
    }

}


@media (max-width: 1000px) {
    .main {
        max-width: 1000px;
        min-width: 300px;
        min-height: 517px;
        background: #fff;
        padding: 20px 150px;

        &__form {
            margin-top: 4px;

            &-two-inputs-block {
                margin-top: 19px;
                display: flex;
                flex-direction: column;

            }
            &-one-inputs-block{
                margin-top: 19px;
                display: flex;
            }
            &-input{
                width: 100%;

                &:nth-child(1){
                    margin-right: 21px;
                }
                &:nth-child(2){
                    margin-top: 19px;

                }

                &-alone{
                    width: 100%;
                    margin-right: 0;
                }
            }
            &__buttons{
                margin-top: 30px;
                margin-bottom: 6px;
                display: flex;

                &-save{
                    font-family: var(--firstFont);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    letter-spacing: 0.4px;
                    color: #FFFFFF;

                    padding: 10px 22px;

                    background: #7367F0;
                    border-radius: 5px;

                    &:hover{
                        transform: scale(0.95);
                    }
                    cursor: pointer;
                }

                &-discard{
                    margin-left: 16px;

                    font-family: var(--firstFont);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    letter-spacing: 0.4px;
                    color: #82868B;

                    padding: 10px 23px;

                    background: #ffffff;
                    border: 1px solid #82868B;
                    border-radius: 5px;

                    &:hover{
                        transform: scale(0.95);
                    }
                    cursor: pointer;
                }
            }
        }

    }
}
