.edit-button{

	font-family: var(--firstFont);
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: #5E5873;

	padding: 9px 10px 9px 10px;
	height: 29px;
	width: 120px;

	background: #f6f6f8;
	border-radius: 5px;

	border: 1px solid #d8d6de;

	display: flex;
	align-items: center;

	&:hover{
		transform: scale(0.95);
	}
	cursor: pointer;

	svg{
		margin-left: 7px;
	}

	max-width: 115px;

	display: flex;
	justify-content: space-between;
}
