.customers{
    max-width: 828px;
    min-width: 500px;
    min-height: 517px;
    background: #fff;
    padding: 20px 0;

    &__header{
        padding: 0 25px;
        margin-bottom: 20px;
    }
    &__list{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


    }
}

.MuiTableCell-head{
    background: #f3f2f7;
    font-family: var(--firstFont);
    font-style: normal;

    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.MuiTableCell-root {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}
.scaleIcon:hover{
    transform: scale(0.95);
    cursor: pointer;
}
