.input{
    width: 100%;

    &__title{
        margin-bottom: 5px;

        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #6E6B7B;

    }
}

.input__wrap{
    display: flex;
    align-items: center;
    padding: 0px 15px;
    background: #fff;
    border: 1px solid #d8d6de;
    border-radius: 5px;
    height: 38px;
}

.input__wrap_focus{
    border: 1px solid #4790F2;
}

.input__wrap_error{
    border: 1px solid rgba(255, 60, 0, 0.6);
}

.input__input{
    font-family: var(--firstFont);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    color: #0c0c0c;
    flex: 1;
    background: transparent;
}

.input__input::placeholder{
    color: #B9B9C3;
}

.input .input__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.input__error{
    padding: 8px 23px 0px;
}

.input__error-text{
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: rgba(255, 60, 0, 0.6);
}
