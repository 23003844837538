.button-wrapper{
	position: relative;

	.button{

		font-family: var(--firstFont);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.4px;
		color: #FFFFFF;

		padding: 10px 63px;

		background: #7367F0;
		border-radius: 5px;

		&:hover{
			transform: scale(0.95);
		}
		cursor: pointer;

	}
	.helper-button{
		display: none;
		position: absolute;
		top: -20px;
		left: 0;
		color: #898989;
	}

	button:disabled,
	button[disabled]{
		font-family: var(--firstFont);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		letter-spacing: 0.4px;
		color: #FFFFFF;

		&:hover{
			transform: scale(1);
		}

		padding: 10px 63px;

		background: #c0c0c2;
		border-radius: 5px;

		cursor: revert;
		&:hover ~ .helper-button {
			display: block;
			width: 300px;
			transition: 0.5s;
		}
	}
}

