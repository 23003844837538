.login-page {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;

}

.login-form__wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    margin: auto;
}


.login__right {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    flex: 1;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 50px;
}

.login__left {
    border-bottom-tight-radius: 20px;
    border-top-tight-radius: 20px;
    flex: 1;
    background-color: #f4f9fd;
    display: flex;
    justify-content: center;
    flex-direction: column;

    img {
        width: 100%;
        height: 400px;
    }

}

.sign-in__wrap-input {
    margin-bottom: 26px;
}

.sign-in__wrap-checkbox {
    margin-bottom: 40px;
}

.sign-in__wrap-btn {
    margin-bottom: 20px;
}


.sign-in__wrap-sign-up {
    margin-top: auto;
}

.sign-in__sign-up {
    font-family: var(--firstFont);
    font-weight: 600;
    font-size: var(--fontSizeMiddleText);
    color: var(--newColor4);
    letter-spacing: 0.02em;
    text-align: center;
}

.sign-in__sign-up a {
    color: var(--newColor4);
}

@media (max-width: 1000px) {
    .login__left {
        display: none;
    }
    .login-form__wrapper {
        //padding: 20px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .login__right {
        border-radius: 20px;
        padding: 50px 20px;
    }
}
