.profile-mob-section{
    max-width: 356px;
    min-width: 300px;
    min-height: 730px;
    background: #fff;

    padding: 20px;

    margin: 0 auto;

    &__main-block{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
    &__ava-block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-avatar{
            cursor: pointer;
            width: 106px;
            height: 106px;
            border-radius: 5px;

            margin-top: 23px;
            background: #b9b3f7;

            img{
                width: 106px;
                height: 106px;
            }
        }

        &-name{
            margin-top: 16px;
            cursor: pointer;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #5E5873;
        }

        &-auther{
            margin-top: 8px;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #7367F0;

            padding: 1px 9px;
            background: rgba(115, 103, 240, 0.12);
            border-radius: 4px
        }
    }


    &__detailsBlock{
        margin-top: 38px;

        &-title{
            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #5E5873;

            margin-bottom: 7px;
        }
        &-underLine{
            width: 100%;
            height: 1px;
            background: #EBE9F1;

            margin-bottom: 16px;

        }
        &-info{

        }
    }
    &__button{
        margin-top: 21px;
        display: flex;
        align-items: center;
        justify-content: center;


        button{
            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #EA5455;

            cursor: pointer;
            padding: 10px 23px;
            border: 1px solid #EA5455;
            border-radius: 5px;

            &:hover{
                transform: scale(0.95);
            }
        }
    }
}


@media (max-width: 1000px) {
    .profile-mob-section{
        min-width: 340px;
        max-width: 1000px;
        min-height: 271px;

        border-radius: 6px;
        padding: 15px;

        display: flex;

        &__main-block{
            margin-right: 10px;
        }

        &__ava-block{

            &-avatar{
                width: 106px;
                height: 106px;

                margin-top: 0;

                img{
                    width: 106px;
                    height: 106px;
                }
            }

            &-name{
                margin-top: 13px;
                font-size: 12px;
            }

            &-auther{
                cursor: pointer;
                margin-top: 6px;
                font-size: 10px;
            }
        }

        &__detailsBlock{
            margin-top: 0;

            &-title{
                display: none;
            }
            &-underLine{
                display: none;

            }
        }

        &__button{
            button{
                font-size: 12px;
                cursor: pointer;
                padding: 7px 22px;

            }
        }
    }
}
