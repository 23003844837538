.main{
    max-width: 828px;
    min-width: 500px;
    min-height: 517px;
    background: #fff;
    padding: 20px 25px;

    border-radius: 6px;

    &__title{
        margin-top: 10px;
        text-align: center;

        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #5E5873;
    }

    &__button{
        margin-top: 30px;
    }

    &__last-reports-block{
        margin-top: 28px;
        &-title{
            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #5E5873;
        }
        &-list{

        }
    }
}

@media (max-width: 1000px) {
    .main{
        max-width: 1000px;
        min-width: 320px;
        min-height: 517px;
        background: #fff;
        padding: 20px 25px;

        &__button{
            margin-top: 30px;
        }

        &__last-reports-block{
            margin-top: 28px;
            &-title{
                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #5E5873;
            }
            &-list{

            }
        }
    }
}

.info-report{
    max-width: 1000px;
    min-height: 626px;
    background: #fff;
    padding: 20px 25px 20px;
    width: 100%;

    margin: auto auto;

    position: relative;

    border-radius: 10px;

    &__title{
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;
        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #6E6B7B;
    }

    &__list{

        &-item{
            display: flex;
            flex-direction: row;
            p{
                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #6E6B7B;
            }
            span{
                font-family: var(--firstFont);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #6E6B7B;

                margin-left: 6px;
            }
        }
    }

    &__buttons{
        margin-top: 30px;
        margin-bottom: 6px;
        display: flex;

        &-discard{
            margin-left: 16px;

            font-family: var(--firstFont);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #82868B;

            padding: 10px 23px;

            background: #ffffff;
            border: 1px solid #82868B;
            border-radius: 5px;

            &:hover{
                transform: scale(0.95);
            }
            cursor: pointer;
        }
    }
}


@media (max-width: 500px) {
    .profile{
        max-width: 1000px;
        min-width: 300px;


    }
}
